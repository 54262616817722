import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import NewsletterCard from "../../components/NewsletterCard";
import EmailIcon from "../../img/images/icons/contact/noun_Email_1740173.png";
import LocationIcon from "../../img/images/icons/contact/noun_Location_1609448.png";
import DirectionIcon from "../../img/images/icons/contact/noun_directions_2681773.png";
import MapIcon from "../../img/images/icons/contact/noun_location map_1042985.png";
import PhoneIcon from "../../img/images/icons/contact/noun_Phone_3509452.png";
import { StyledTextField } from "../../components/PaymentWindow";
import withStyles from "@material-ui/core/styles/withStyles";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const ContactTextField = withStyles({
  root: {
    "& .MuiInput-underline:after, .MuiInput-underline:before": {
      bottom: "-8px",
    },
    "& label": {
      color: "#ffffff !important",
    },
  },
})(StyledTextField);

const SubmitButton = () => (
  <button type={"submit"} id={"contact-form-submit-button"}>
    Send
  </button>
);

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
      submitting: false,
      name: "",
      email: "",
      mobile: "",
      query: "",
      message: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  onDirectionsClick = () => {
    window.open("https://g.page/Unschool?share", "_blank");
  };

  render() {
    const { submitting, name, email, mobile, query, message } = this.state;
    let pageTitle = "Get in touch with us | Unschool";
    let pageDescription =
      "Be a part of the learner community, intern program or companies who trust Unschoolers to bring young, innovative and enthusiastic minds to their workplace | admin@unschool.in";
    return (
      <Layout title={pageTitle} description={pageDescription}>
        <section id="contact-us-section">
          <div className="row">
            <div className="col-md-6">
              <form
                id="contact-form-container"
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <h2>Get in touch with us</h2>
                <h6>
                  Reach out to us to say hello or for all your queries. We
                  usually take 48 hours to respond
                </h6>
                <div id="contact-form">
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <ContactTextField
                    label="Name"
                    fullWidth={true}
                    value={name}
                    disabled={submitting}
                    name={"name"}
                    onChange={this.handleChange}
                  />
                  <ContactTextField
                    label="Email"
                    fullWidth={true}
                    value={email}
                    name={"email"}
                    onChange={this.handleChange}
                    disabled={submitting}
                  />
                  <ContactTextField
                    label="Mobile Number"
                    fullWidth={true}
                    value={mobile}
                    name={"mobile"}
                    onChange={this.handleChange}
                    disabled={submitting}
                  />
                  <ContactTextField
                    label="Your Query"
                    fullWidth={true}
                    value={query}
                    name={"query"}
                    onChange={this.handleChange}
                    disabled={submitting}
                  />
                  <ContactTextField
                    label="Your Message"
                    fullWidth={true}
                    value={message}
                    name={"message"}
                    onChange={this.handleChange}
                    disabled={submitting}
                  />
                  <SubmitButton />
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <div id="contact-us-map-container">
                <iframe
                  width="100%"
                  height="450"
                  className="mobile-hidden"
                  frameBorder="0"
                  src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJCdB-fj6PyzsRPl3EEPww4Q0&key=${process.env.GATSBY_GOOGLE_MAPS_KEY}`}
                  allowFullScreen
                />
              </div>
            </div>
          </div>

          <button
            className="desktop-hidden"
            id="mobile-directions-button"
            onClick={this.onDirectionsClick}
          >
            <div id="mobile-maps-icon-container">
              <img src={MapIcon} alt="Map Icon" />
            </div>
            Feel free to stop by to say hello!
            <div id={"mobile-directions-icon-container"}>
              <img src={DirectionIcon} alt="Direction Icon" />
            </div>
          </button>

          <div className="row" id="contact-information-section">
            <div className="col-md-3 contact-info-container">
              <div className="contact-us-info-icon">
                <img src={EmailIcon} alt="Email Icon" />
              </div>{" "}
              <div className="contact-info-text">
                <a href="mailto:support@unschool.in">support@unschool.in</a>
              </div>
            </div>
            <div className="col-md-3 contact-info-container">
              <div className="contact-us-info-icon">
                <img src={PhoneIcon} alt="Phone Icon" />
              </div>{" "}
              <div className="contact-info-text">
                <a href="tel:+919154254541">+91 9154254541</a>
              </div>
            </div>
            <div className="col-md-3 contact-info-container">
              <div className="contact-us-info-icon">
                <img src={LocationIcon} alt="Location Icon" />
              </div>{" "}
              <div className="contact-info-text">
                Gachibowli, Hyderabad, Telangana 500032
              </div>
            </div>
          </div>

          <div className="row" id="contact-page-newsletter-container">
            <NewsletterCard />
          </div>
        </section>
      </Layout>
    );
  }
}
